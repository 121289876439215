import { useMemo } from 'react';

import type {
  CellClassParams,
  CellValueChangedEvent,
} from '@ag-grid-community/core';

import {
  getDateCellConfig,
  getMoneyCellConfig,
  getTextCellConfig,
  makeEditableIf,
} from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import type { UnprocessedPOResponse } from 'shared/lib/types';
import { IntegrationType } from 'shared/lib/types';

export default function usePOListingIntegrationGridColDefs(): CondorColDef[] {
  return useMemo(() => {
    const ignoredCheck = (data: UnprocessedPOResponse) => !data.ignored;

    const studyIdInvalidCheck = ({ data }: CellClassParams) =>
      (data.study_id === '' || data.study_id === null) && !data.ignored;

    const poAmountInvalidCheck = ({ data }: CellClassParams) =>
      (data.po_amount === '' ||
        data.po_amount === '0.00' ||
        data.po_amount === null ||
        data.po_amount === 0) &&
      !data.ignored;

    return [
      { field: 'trace_id', hide: true, width: 150 },
      {
        children: [
          {
            field: 'error',
            headerName: '',
            flex: 0,
            width: 10,
            cellRenderer: 'AgGridIntegrationsErrorRenderer',
            suppressHeaderMenuButton: true,
          },
          {
            headerName: 'Ignore',
            field: 'ignored',
            flex: 0,
            width: 75,
            resizable: false,
            cellRenderer: 'AgGridIntegrationsIgnoreRenderer',
          },
          {
            headerName: 'Split',
            field: 'split',
            resizable: false,
            width: 65,
            flex: 0,
            cellRenderer: 'AgGridIntegrationsSplitRenderer',
            cellRendererParams: {
              integrationType: IntegrationType.PURCHASE_ORDER_SNAPSHOT,
            },
          },
        ],
      },
      {
        headerName: 'TRIAL',
        children: [
          {
            headerName: 'Condor Value',
            field: 'study_id',
            width: 150,
            cellRenderer: 'AgGridIntegrationsTrialIdRenderer',
            cellRendererParams: {
              integrationType: IntegrationType.PURCHASE_ORDER_SNAPSHOT,
            },
            wrapText: true,
            autoHeight: true,
            cellClassRules: {
              'ag-cell-error-selected': studyIdInvalidCheck,
            },
            ...makeEditableIf(({ data }) => ignoredCheck(data)),
          },
          {
            headerName: 'Integration Value',
            field: 'external_study_id',
            ...getTextCellConfig(),
            width: 150,
            editable: false,
          },
        ],
      },
      {
        headerName: 'PO #',
        children: [
          {
            headerName: 'Condor Value',
            field: 'po_number',
            ...getTextCellConfig(),
            width: 150,
            editable: true,
          },
          {
            headerName: 'Integration Value',
            field: 'external_po_number',
            ...getTextCellConfig(),
            width: 150,
            editable: false,
          },
        ],
      },
      {
        headerName: 'PO AMOUNT',
        children: [
          {
            headerName: 'Condor Value',
            field: 'po_amount',
            valueGetter:
              'Number.isNaN(node.data.po_amount) ? node.data.po_amount : Number(node.data.po_amount)',
            ...getMoneyCellConfig({ currencyKey: 'currency' }),
            editable: true,
            width: 150,
            cellClassRules: {
              'ag-cell-error-selected': poAmountInvalidCheck,
            },
          },
          {
            headerName: 'Integration value',
            field: 'external_po_amount',
            valueGetter:
              'Number.isNaN(node.data.external_po_amount) ? node.data.external_po_amount : Number(node.data.external_po_amount)',
            ...getMoneyCellConfig({ currencyKey: 'external_currency' }),
            width: 150,
            editable: false,
          },
        ],
      },
      {
        headerName: 'CURRENCY',
        children: [
          {
            headerName: 'Condor Value',
            field: 'currency',
            width: 150,
            editable: true,
            ...getTextCellConfig(),
            onCellValueChanged: ({ api, node }: CellValueChangedEvent) => {
              api.redrawRows({ rowNodes: [node] });
            },
          },
          {
            headerName: 'Integration Value',
            field: 'external_currency',
            ...getTextCellConfig(),
            width: 150,
            editable: false,
          },
        ],
      },
      {
        headerName: 'Vendor',
        children: [
          {
            headerName: 'Condor Value',
            field: 'vendor',
            aggFunc: 'totalLabel',
            ...getTextCellConfig(),
            width: 150,
            editable: true,
          },
          {
            headerName: 'Integration Value',
            field: 'external_vendor',
            ...getTextCellConfig(),
            width: 150,
            editable: false,
          },
        ],
      },
      {
        headerName: 'G/L account',
        children: [
          {
            headerName: 'Condor Value',
            field: 'gl_account',
            ...getTextCellConfig(),
            width: 150,
            editable: true,
          },
          {
            headerName: 'Integration Value',
            field: 'external_gl_account',
            ...getTextCellConfig(),
            width: 150,
            editable: false,
          },
        ],
      },
      {
        headerName: 'Description',
        children: [
          {
            headerName: 'Condor Value',
            field: 'description',
            ...getTextCellConfig(),
            width: 150,
            editable: true,
          },
          {
            headerName: 'Integration Value',
            field: 'external_description',
            ...getTextCellConfig(),
            width: 150,
            editable: false,
          },
        ],
      },
      {
        headerName: 'PO date',
        children: [
          {
            headerName: 'Condor Value',
            field: 'date',
            ...getDateCellConfig(),
            width: 150,
            editable: true,
          },
          {
            headerName: 'Integration Value',
            field: 'external_date',
            ...getTextCellConfig(),
            width: 150,
            editable: false,
          },
        ],
      },
      {
        headerName: 'Status',
        children: [
          {
            headerName: 'Condor Value',
            field: 'status',
            ...getTextCellConfig(),
            width: 150,
            editable: true,
          },
          {
            headerName: 'Integration Value',
            field: 'external_status',
            ...getTextCellConfig(),
            width: 150,
            editable: false,
          },
        ],
      },
    ];
  }, []);
}
