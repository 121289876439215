import { useMemo } from 'react';

import type { CurrencyCode } from 'currencies';
import { ForecastParameterType } from 'shared/lib/types';

import { useGetForecastParametersByForecastQuery } from 'shared/api/rtkq/forecastparameters';

import useForecast from './useForecast';
import { GLOBAL_REGION_NAME } from './useForecastParamEditorRows';

const COST_TYPES = [
  'STARTUP',
  'CLOSE',
  'ANNUAL',
  'QUARTERLY',
  'MONTHLY',
] as const;

type CostType = (typeof COST_TYPES)[number];

const COST_TYPE_LABELS: Record<CostType, string> = {
  STARTUP: 'startup',
  CLOSE: 'close',
  ANNUAL: 'annual',
  QUARTERLY: 'quarterly',
  MONTHLY: 'monthly',
};

const PARAMETER_TYPE = {
  STARTUP: ForecastParameterType.SITE_COST_STARTUP,
  CLOSE: ForecastParameterType.SITE_COST_CLOSE,
  ANNUAL: ForecastParameterType.SITE_COST_ANNUAL,
  QUARTERLY: ForecastParameterType.SITE_COST_QUARTERLY,
  MONTHLY: ForecastParameterType.SITE_COST_MONTHLY,
} as const;

type SiteAverageRow = {
  row_title: string;
  actual_average: number;
  forecasted_average: number;
  trialCurrency: CurrencyCode;
  isTotal?: boolean;
  parameter_trace_id?: string;
  parameter_type?: ForecastParameterType;
  region_name?: string;
};

function useSiteAveragesGridRows(): SiteAverageRow[] {
  const { loading, generatedForecast, forecast } = useForecast();

  const { currentData: forecastParameters } =
    useGetForecastParametersByForecastQuery(forecast.trace_id);

  return useMemo(() => {
    if (loading || !generatedForecast) {
      return [];
    }

    const costsData = generatedForecast.avg_site_admin_costs_by_region;
    const trialCurrency = generatedForecast.trial_currency;

    const regions: string[] = [];

    for (const regionData of Object.values(costsData)) {
      for (const region of Object.keys(regionData)) {
        if (!regions.includes(region)) {
          regions.push(region);
        }
      }
    }

    const rows: SiteAverageRow[] = [];
    for (const costType of COST_TYPES) {
      const regionData = costType in costsData ? costsData[costType] : {};
      const forecastParams = forecastParameters?.filter(
        (parameter) => parameter.type === PARAMETER_TYPE[costType],
      );

      for (const region of regions) {
        const defaultValue = regionData[region] || 0;
        const forecastParameter = forecastParams?.find((parameter) => {
          if (region === GLOBAL_REGION_NAME) {
            return parameter.region_name === '';
          }
          return parameter.region_name === region;
        });
        rows.push({
          row_title: `Avg site ${COST_TYPE_LABELS[costType]} costs ${region}`,
          actual_average: defaultValue,
          forecasted_average: Number(forecastParameter?.value ?? defaultValue),
          trialCurrency,
          parameter_trace_id: forecastParameter?.trace_id ?? '',
          parameter_type: PARAMETER_TYPE[costType],
          region_name: region,
        });
      }

      const values = Object.values(regionData);
      const actualsAverage = values.length
        ? values.reduce((sum, val) => sum + val, 0) / values.length
        : 0;
      const forecastedAverage =
        forecastParams?.reduce(
          (sum, parameter) => sum + Number(parameter.value),
          0,
        ) ?? 0;

      rows.push({
        row_title: `Avg site ${COST_TYPE_LABELS[costType]} costs global`,
        actual_average: actualsAverage,
        forecasted_average: forecastedAverage,
        trialCurrency,
        isTotal: true,
      });
    }

    return rows;
  }, [loading, generatedForecast, forecastParameters]);
}

export default useSiteAveragesGridRows;
