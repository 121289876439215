import { useMemo } from 'react';

import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import { makeEditableIf } from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import { selectForecast } from 'forecasting/state/slices/forecastSlice';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import type { ForecastParameterType } from 'shared/lib/types';

import {
  useCreateForecastParameterMutation,
  useUpdateForecastParameterMutation,
} from 'shared/api/rtkq/forecastparameters';

import { GLOBAL_REGION_NAME } from './useForecastParamEditorRows';

const usePatientAveragesGridColDefs = (): CondorColDef[] => {
  const forecast = useSelector(selectForecast);
  const [createForecastParameter] = useCreateForecastParameterMutation();
  const [updateForecastParameter] = useUpdateForecastParameterMutation();

  const isEditable = useFeatureFlag(
    'forecasting_parameters_editable_patient_averages',
  );

  const themeMode = useTheme().palette.mode;

  return useMemo(() => {
    const handleParameterUpsert = (
      parameterTraceId: string | undefined,
      regionName: string,
      parameterType: ForecastParameterType,
      newValue?: number | string,
    ) => {
      void (async () => {
        if (newValue === undefined) {
          return;
        }

        if (!parameterTraceId) {
          await createForecastParameter({
            forecast: forecast.trace_id,
            type: parameterType,
            region_name: regionName === GLOBAL_REGION_NAME ? '' : regionName,
            value: newValue as number,
          }).unwrap();
        } else {
          await updateForecastParameter({
            trace_id: parameterTraceId,
            value: newValue as number,
          }).unwrap();
        }
      })();
    };

    return [
      {
        field: 'row_title',
        headerName: 'Patient averages',
        flex: 1,
      },
      {
        field: 'actual_average',
        headerName: 'Actual',
        flex: 1,
        cellRendererSelector: 'optionalCurrencyNumberCellRendererSelector',
      },
      {
        field: 'forecasted_average',
        headerName: 'Scenario',
        flex: 1,
        cellRendererSelector: 'optionalCurrencyNumberCellRendererSelector',
        ...(isEditable && !forecast.locked
          ? {
              ...makeEditableIf(
                ({ data }) => !data.isTotal && !data.isEmpty,
                themeMode,
              ),
              cellEditor: 'AgGridTextEditor',
              cellEditorParams: { inputType: 'number' },
              valueParser: (params) => Number(params.newValue),
              onCellValueChanged: (event) => {
                handleParameterUpsert(
                  event.data.parameter_trace_id,
                  event.data.region_name,
                  event.data.parameter_type,
                  event.newValue,
                );
              },
            }
          : {}),
      },
      {
        field: 'parameter_trace_id',
        hide: true,
      },
      {
        field: 'parameter_type',
        hide: true,
      },
      {
        field: 'region_name',
        hide: true,
      },
    ];
  }, [
    isEditable,
    forecast,
    createForecastParameter,
    updateForecastParameter,
    themeMode,
  ]);
};

export default usePatientAveragesGridColDefs;
